import Custom500 from './500';
import { Metadata, PageContainer, CMSModule } from '@/components';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import nextI18nConfig from '../next-i18next.config';
import { setPageId, setPageType, setRobot, wrapper } from '@/store/index';
import Header from '@/modules/Header';

const Index = ({ errorCode, errorMessage, metadata, moduleContent, footer, header, isAnchorBar }) => {
  if (errorCode) {
    return <Custom500 errorCode={errorCode} errorMessage={errorMessage} />;
  }

  const transparentHeader =
    moduleContent && ['HeroFullBleedModule', 'HeroFullBleedLeftModule'].includes(moduleContent[0]?.moduleName);

  return (
    <>
      <Metadata metadata={metadata} />
      <Header header={header} transparentHeader={transparentHeader} />
      <PageContainer>
        {moduleContent?.map((module) => (
          <CMSModule key={module.moduleId} module={module} isAnchorBar={isAnchorBar} />
        ))}

        <CMSModule module={footer} />
      </PageContainer>
    </>
  );
};

export default Index;

export const getServerSideProps = wrapper.getServerSideProps((store) => async (context) => {
  const { resolvedUrl, locale, defaultLocale, res, req } = context;

  // workaround for missing assets spamming the API
  if (resolvedUrl.startsWith('/_next/')) {
    res.statusCode = 404;
    res.end();
    return;
  }

  let errorCode,
    errorMessage = null;
  try {
    const dataRes = await fetch(
      `${process.env.RESOURCE_PATH}api${defaultLocale !== locale ? '/' + locale : ''}${resolvedUrl?.endsWith('/') ? resolvedUrl.slice(0, -1) : resolvedUrl}`,
      {
        headers: { 'x-cdn-load': req.headers['x-cdn-load'] },
        keepalive: true,
      },
    );

    errorCode = dataRes?.ok ? false : dataRes?.status;

    const contentType = dataRes?.headers.get('content-type');
    if (errorCode === 301) {
      res.writeHead(301, {
        Location: dataRes?.headers.get('location'),
      });
      res.end();
    } else if (errorCode === 500) {
      errorMessage =
        process.env.NODE_ENV === 'development'
          ? 'BE Internal Server Error'
          : "An error has occurred and we're working to fix the problem.";
    } else if (!contentType || contentType.indexOf('application/json') === -1) {
      if (errorCode === 404) {
        errorMessage = 'The page you were looking for doesn’t exist.';
        res.statusCode = 404;
      } else {
        errorCode = 500;
        errorMessage =
          process.env.NODE_ENV === 'development'
            ? 'BE response not JSON Object'
            : "An error has occurred and we're working to fix the problem.";
        res.statusCode = 500;
      }
    } else if (!errorCode || errorCode === 404) {
      const data = await dataRes.json();

      if (data?.metadata?.pageType === 'sp404PageNotFound' && res) {
        res.statusCode = 404;
      }
      const isRobot = !!req.headers['is-robot'];
      store.dispatch(setRobot(isRobot));
      res.setHeader(
        'Cache-Control',
        `public, max-age=${resolvedUrl.includes('isPreview=true') ? 0 : isRobot ? 2592000 : process.env.CDN_CACHE_MAX_AGE || 86400}`,
      );

      if (data?.metadata) {
        store.dispatch(setPageId(data.metadata.pageId));
        store.dispatch(setPageType(data.metadata.pageType));
      }

      const translations = await serverSideTranslations(locale, ['common'], nextI18nConfig);

      // Move AnchorBarModule above BreadcrumbsModel or to the end
      const anchorBarModule = data.moduleContent.find((module) => module.moduleName === 'AnchorBarModule');
      if (anchorBarModule) {
        const fromIndex = data.moduleContent.indexOf(anchorBarModule);
        data.moduleContent.splice(fromIndex, 1);
        const breadcrumbsModelIndex = data.moduleContent.findIndex(
          (module) => module.moduleName === 'BreadcrumbsModel',
        );
        data.moduleContent.splice(
          breadcrumbsModelIndex >= 0 ? breadcrumbsModelIndex : data.moduleContent.length,
          0,
          anchorBarModule,
        );
      }

      return {
        props: {
          ...data,
          isAnchorBar: !!anchorBarModule,
          ...translations,
        },
      };
    }
  } catch (error) {
    errorCode = 500;
    console.error(error);
    errorMessage = error.message;
  }

  res.statusCode = 500;
  return {
    props: { errorCode, errorMessage },
  };
});
