import NextLink from 'next/link';

// pages/500.js
export default function Custom500({ errorCode, errorMessage }) {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-10 px-6 py-20 md:px-10 md:py-[88px] lg:px-[272px] lg:py-[136px]">
      <h1 className="font-larken text-desktop-small font-light leading-7 md:text-desktop-medium md:leading-desktop-medium">
        {errorCode}
      </h1>
      <div className="text-center">
        <p className="mb-4 font-gotham text-sm font-n-light leading-4 text-black/50 md:mb-3">Sorry</p>
        <p className="font-gotham text-base font-s-light tracking-paragraph text-black/75 md:text-desktop-small md:leading-8">
          {errorMessage}
        </p>
      </div>
      <NextLink href="/" className="btn secondary dark w-full md:w-fit">
        Back to Homepage
      </NextLink>
    </div>
  );
}
